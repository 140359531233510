import React from 'react';
import FullScreenPlayer from './FullScreenPlayer';

function App() {
  const videoUrl = 'https://www.youtube.com/watch?v=_53H7UKdcEE';

  return (
    <div className="App">
      <FullScreenPlayer 
      
      url={videoUrl} 
      controls={true}
      playing={true}
      muted={true}
      loop={true}
      volume={0.8}

      
      />
    </div>
  );
}

export default App;
